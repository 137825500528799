@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Oxygen', sans-serif;
}

